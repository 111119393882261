<template>
  <div class="ContactPage">
    <div class="ContactPage__icon">
      <i class="fal fa-comment-alt-smile"></i>
    </div>
    <div class="ContactPage__description">
      <h2>{{$ml.get('contactPage.header')}}</h2>
      <!-- <vue-markdown class="description">{{$ml.get('contactPage.description')}}</vue-markdown> -->
      <div class="description">
        Please see the shipping info page if you have any questions regarding your order and delivery.
        <a href="/delivery">Click here</a>
        <br>
        <br>
        If you need to contact our customer care, you can reach us via email at
        <a :href="`mailto:support@vegankind.com`">
          support@vegankind.com
        </a> 😀
        <br>
        We are always here Monday to Friday, from 9 am to 5 pm. ☺️
        <br>
        <br>
        Please note it may take up to 5 working days for your email to be responded.
        <br>
        <br>
        If you have an urgent enquiry regarding your Order, please get in touch with the merchant directly through the chat on the sidebar on our website.
        <br>
        <ul>
          <li>Just log into your <b>Account</b></li>
          <li>Open the <b>Sidebar</b></li>
          <li>Go to <b>Purchases</b></li>
          <li>Find the correct Order you want to enquire about</li>
          <li>Scroll below to the <b>Chat Message</b> window - you can contact the merchant directly here.</li>
          <li>The system works like a chat - you will receive the merchant’s reply in the same place. We will send you an email with a notification.</li>
          <li>Please note the message should be relevant to the Order. Non-related messages won’t be prioritised.</li>
        </ul>
        <br>
        <h4>For Brands</h4>
        If you need help regarding your account and technical support, please contact:
        <a :href="`mailto:hello@vegankind.com`">
          hello@vegankind.com
        </a>
        <br>
        <br>
        Want to sell your products at the {{ siteConstants.siteName }} marketplace?
        We would love to hear from you 😃
        <br>
        Is there a product or venue you recommend?
        Drop us a note or a high five 😃
        <br>
        <a :href="`mailto:hello@vegankind.com`">
          hello@vegankind.com
        </a>
        <br>
        <br>
        <br>
        <!-- If you need to contact us, please be aware that our working time is every day from 9 am to 5 pm,
        from Monday to Friday. This working time includes both - our office, warehouse and customer care teams.
        <br>
        <br> -->
        We believe that taking time off work is healthy for the body, mind and relationships.
        We know that disconnecting from work on a regular basis allows you to have less stress and enjoy a happier life.
        <br>
        <br>
        The health and well-being of all our employees are the most important for us.
        We want all our employees to have a good work and life balance.
        We want them to be able to rest in the evenings daily and have relaxing weekends,
        spending time with their loved ones and doing whatever they like.
        <br>
        <br>
        At the same time, we want to reassure you that we are all available for your service every day
        from Monday to Friday, and during our working hours, we will take care of all customer requests.
        <br>
        <br>
        <br>
        Trading Name: {{ siteConstants.siteName }} Market
        <br>
        Registered Name: Wholefood Earth Ltd
        <br>
        Registration No. 09650943
        <br>
        VAT No. GB 227645691
        <br>
        Registered in England & Wales
        <br>
        Registered Address: 59-63 Hopes Lane, Ramsgate CT12 6UW, Kent, United Kingdom
      </div>
    </div>
  </div>
</template>

<script>
// import VueMarkdown from 'vue-markdown'
import siteConstants from '@/const.js'

export default {
  name: 'ContactPage',
  components: {
    // VueMarkdown
  },
  data () {
    return {
      siteConstants
    }
  }
}
</script>

<style scoped lang="stylus">
.disabled
  opacity 0.1

.ContactPage
  display flex
  background white
  align-items flex-start
  overflow-y auto
  justify-content center
  height 100%
  // flex-wrap wrap
  padding 50px 0 0 0
  box-sizing border-box
  &__icon
    margin-top 70px
    font-size 140px
    display inline-block
    padding-right: 30px;
  &__description
    text-align left
    max-width 600px
    display inline-block
    box-sizing border-box
    color #555
    font-size 14px
    padding 70px 0 0 0px
    h2
      font-size 27px
      font-weight 300
      color black
      font-family: 'Bebas Neue', sans-serif;
    .description
      line-height 1.6
      margin 20px 0 0 0
      // font-size 16px
    span
      margin-right 15px
      img
        margin-top 80px
        margin-bottom 20px

@media screen and (max-width: 550px)
  .ContactPage {
    flex-direction: column;
    align-items: center;
    &__icon {
      padding-right: 0px;
      margin-top: 0
    }
  }

@media screen and (max-width: 868px)
  .ContactPage
    padding 30px 10px
    &__description
      padding 30px 0 0 0px
</style>
